<template>
	<div class="info">
		<div class="top">
			<form action="" v-on:submit.prevent="searchs">
				<div class="wai">
					<div><span>药店名称</span><input type="text" v-model="name" placeholder="请输入药店名称"></div>
					<div><span>座机</span><input type="text" v-model="tel" placeholder="请输入座机号码"></div>
					<div><span>地址</span><input type="text" v-model="address" placeholder="请输入地址"></div>
					<div><button type="submit">搜索</button></div>
				</div>
			</form>
		</div>
		<div class="list">
			<table border="0" cellspacing="0" cellpadding="0" bordercolor='#979797'>
				<tr>
					<th class="th1">药店名称</th>
					<th class="th3 th3_3">座机</th>
					<th class="th4">地址</th>
				</tr>
				<tr :class="[index%2 == 0?'td_1':'td_2']" v-for="(item,index) in list" >
					<td>{{item.name}}</td>
					<td class="th3_3">{{item.tel}}</td>
					<td>{{item.address}}</td>
				</tr>
			</table>

		</div>
		<div>
			<div class="fen">
				<el-pagination
				  background
				  layout="prev, pager, next"
				  @prev-click='shang'
				  @next-click='xia'
				  @current-change='click_id'
				  :page-count='counts'>
				</el-pagination>
			</div>
		</div>
	</div>

</template>

<script>
	// 引入axios
	import axios from 'axios';
	// 引入端口配置文件
	import API_LIST from '@/list.config.js';
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import qs from 'qs'
	export default {

		name: "Zi",
		props: {

		},
		data() {
			return {
				page: 1,
				hidden: true,
				num: [],
				counts: Number, // 总页数
				list: [],
				ind: 0,
				name: '',
				mobile: '',
				tel: '',
				address: '',
				from: '',
				search: '',
				area: '',
				coordinate: '',

			}

		},
		watch: {

		},
		computed: {

		},

		created() {
			const that = this;

			axios.post(API_LIST.drugList, {
					page: this.page,
					name: '',
					tel: '',
					address: '',
				}, {
					headers: {
						// 'Content-Type': 'application/x-www-form-urlencoded'
						'Content-Type': 'application/json'
					} //加上这个x
				})
				.then(_d => {
					console.log(_d.data)
					if (_d.data.status == 200) {
						// console.log(_d)
						that.list = _d.data.list.list;

						that.counts = _d.data.list.page;
						if (_d.data.list.page == 0) {
							that.hidden = false;
							return false
						}
						if (that.num.length + 5 < that.counts) {
							that.num = this.generateArray(1, 5)
							that.show = true
						} else {
							that.num = that.counts
							that.show = false
						}
					} else if (_d.data.status == 409) {

					}


				})
				.catch(function(error) {
					console.log(error);
				});
		},
		mounted() {

		},


		methods: {
			// 上一页
			shang() {
				const that = this;
				if (this.page == 1) {
					ElMessage({
						message: '已经是第一页了',
						type: 'warning',
					})
				} else {
					this.ind--;
					this.page--;
					if (this.page < this.num[0]) {
						that.num = this.generateArray(this.num[0] - 5, this.num[0] - 1)
						this.ind = 4;
					}
					axios.post(API_LIST.drugList, {
							page: this.page,
							name: this.name,
							tel: this.tel,
							address: this.address,
						}, {
							headers: {
								// 'Content-Type': 'application/x-www-form-urlencoded'
								'Content-Type': 'application/json'
							} //加上这个x
						})
						.then(_d => {
							console.log(_d.data)
							if (_d.data.status == 200) {
								// console.log(_d)
								that.list = _d.data.list.list;

								that.counts = _d.data.list.page;
								if (_d.data.list.page == 0) {
									that.hidden = false;
									return false
								}
								if (that.num.length + 5 < that.counts) {
									that.num = this.generateArray(1, 5)
									that.show = true
								} else {
									that.num = that.counts
									that.show = false
								}
							} else if (_d.data.status == 409) {

							}


						})
						.catch(function(error) {
							console.log(error);
						});
				}
			},
			// 下一页
			xia() {
				const that = this;
				if (that.page == that.counts) {
					ElMessage({
						message: '已经是最后一页了',
						type: 'warning',
					})
				} else {
					this.ind++;
					this.page++;
					if (this.page > this.num[4]) {
						that.num = this.generateArray(this.num[4] + 1, this.num[4] + 5)
						this.ind = 0;
					}
					axios.post(API_LIST.drugList, {
							page: this.page,
							name: this.name,
							tel: this.tel,
							address: this.address,
						}, {
							headers: {
								// 'Content-Type': 'application/x-www-form-urlencoded'
								'Content-Type': 'application/json'
							} //加上这个x
						})
						.then(_d => {
							console.log(_d.data)
							if (_d.data.status == 200) {
								// console.log(_d)
								that.list = _d.data.list.list;

								that.counts = _d.data.list.page;


							} else if (_d.data.status == 409) {

							}


						})
						.catch(function(error) {
							console.log(error);
						});
				}

			},
			// 搜索
			searchs() {
				const that = this;
				this.ind = 0;
				this.page = 1;
				

				axios.post(API_LIST.drugList, {
						page: this.page,
						name: this.name,
						tel: this.tel,
						address: this.address,
					}, {
						headers: {
							// 'Content-Type': 'application/x-www-form-urlencoded'
							'Content-Type': 'application/json'
						} //加上这个x
					})
					.then(_d => {
						console.log(_d.data)
						if (_d.data.status == 200) {
							// console.log(_d)
							that.list = _d.data.list.list;

							that.counts = _d.data.list.page;
							if (_d.data.list.page == 0) {
								that.hidden = false;
								return false
							}
							if (5 < that.counts) {
								that.num = this.generateArray(1, 5)
								that.show = true
							} else {
								that.num = that.counts
								that.show = false
							}

						} else if (_d.data.status == 409) {

						}


					})
					.catch(function(error) {
						console.log(error);
					});

			},
			// 点击选择
			click_id(item){
				const that = this;
				this.ind = item-1;
				this.page = item;
				
				axios.post(API_LIST.drugList, {
						page: this.page,
						name: this.name,
						tel: this.tel,
						address: this.address,
					}, {
						headers: {
							// 'Content-Type': 'application/x-www-form-urlencoded'
							'Content-Type': 'application/json'
						} //加上这个x
					})
					.then(_d => {
						console.log(_d.data)
						if (_d.data.status == 200) {
							// console.log(_d)
							that.list = _d.data.list.list;
							that.counts = _d.data.list.page;
							
						} else if (_d.data.status == 409) {
							
						}
							
							
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			generateArray(start, end) {
				return Array.from(new Array(end + 1).keys()).slice(start)
			}
		},

	};
</script>

<style scoped="scoped" lang="scss">
	.info {
		width: 100%;
		
		.top {
			display: flex;
			background-color: #fff;

			form {
				display: flex;
				flex-direction: column;
				box-sizing: border-box;
				padding: 20px 30px;
				width: 100%;
				.wai {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					/* justify-content: space-around; */
					margin: 0 0 20px 0;
					align-items: center;
					// justify-content: space-around;
					width: 100%;
					
					div {
						// width: 64px;
						display: flex;
						flex-direction: row;
						align-items: center;
						width: 330px;
						text-align: right;
						margin: 0 100px 30px 0;
					}
					span{
						margin: 0 10px 0 0;
						width: 60px;
					}
					
					input {
						width: 260px;
						height: 46px;
						background: #fff;
						border: 1px solid #b3b3b3;
						border-radius: 6px;
						box-sizing: border-box;
						padding: 0 0 0 5px;
						
					}
					
					button {
						width: 260px;
						height: 46px;
						background: #0078f0;
						color: #fff;
						border-radius: 6px;
						margin: 0 0 0 70px;
						font-size: 18px;
					}
				}
			}
		}

		table {
			width: 100%;
			margin: 0px 0 0 0;
			background-color: #fff;
			// border-right: 1px solid #979797;
			// border-bottom: 1px solid #979797;
			
			th,
			td {
				
				text-align: center;
				font-size: 14px;
				height: 53px;
				// border-top: 1px solid #979797;
				// border-left: 1px solid #979797;
			}
			.td_1{
				background: #fff !important;
			}
			.td_2{
				background: #eee !important;
			}

			.th1 {
				width: 120px;
				background: #0078f0;
				color: #fff;
			}

			.th2 {
				width: 110px;
			}

			.th3 {
				width: 110px;
				background: #0078f0;
				color: #fff;
				
			}
			.th3_3{
				border-right: 1px solid #ddd;
				border-left: 1px solid #ddd;
			}

			.th4 {
				width: 180px;
				background: #0078f0;
				color: #fff;
			}

			.th5 {
				width: 100px;
			}

			.th6 {
				width: 60px;
			}

			.th7 {
				width: 60px;
			}
		}

		.fen {
			display: flex;
			flex-direction: column;
			margin: 50px auto 0;
			width: 500px;

			// width: 300px;
			ul {

				display: flex;
				flex-direction: row;

				li {
					background-color: #add5d4;
					margin-left: 5px;
					color: #333;
					width: 36px;
					height: 36px;
					line-height: 36px;
					text-align: center;

					// padding: 10px;

				}

				.bg {
					background-color: #39A49B !important;
				}

				li:hover {
					background-color: #39A49B !important;
					cursor: pointer;
				}

				.shang,
				.xia,
				.xiaFive {
					width: 87px;
					height: 37px;
				}

			}
		}

	}
</style>
