<template>
	<div class="drugInfo">
		<div class="top">
			<div class="sort">
				<span>所有分类</span>
				<ul>
					<li v-for="item in list.nav2" @click="sort(item.cate1)" v-if="list.nav2">{{item.cate1}}</li>
				</ul>
			</div>
			<div class="lunbo">
				<el-carousel :height="hei">
					<el-carousel-item v-for="item in list.banner" :key="item">
						<img style="width: 560px;height: 420px;" :src="item.img" alt="" @click="detail(item.id)">
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
		<div class="te">
			<div class="title">优品推荐</div>
			<div class="productList">
				<div class="productOne" v-for="item in list.data3" @click="detail(item.id)">
					<img :src="item.img" alt="">
					<div class="info">
						<h4>{{item.name}}</h4>
						<p>{{item.manu}}</p>
					</div>
				</div>
			</div>
			
		</div>
		<!-- 器官 -->
		<!-- <div class="organ">
			<ul class="organTop">
				<li :class="choose == index?'choose':''" v-for="item,index in list2" @click="chooses(index)">{{item.name}}</li>
			</ul>
			<ul class="disease">
				<li  v-for="(item,index) in list2[n].list">{{item}}<div :class="index < 12?'ic':'ic2'"></div></li>
			</ul>
		</div> -->
		<!-- 资讯 -->
		<!-- <div class="new">
			<ul>
				<li v-for="item in list.article1" @click="detail2(item.id)"><span>[疾病类]</span><p>{{item.title}}</p></li>
			</ul>
		</div> -->
		<!-- 带照片的资讯 -->
		<div class="imgNew">
			<ul>
				<li v-for="item in list.article2" @click="detail2(item.id)">
					<img :src="item.image" alt="">
					<div class="info">
						<h4>{{item.title}}</h4>
						<!-- <p>暂无简介</p> -->
						<div>
							<span>发布时间：{{item.addtime}}</span>
							<span>浏览：{{item.click}}</span>
						</div>
					</div>
				</li>
			</ul>
			<!-- <img src="../assets/ceshi7.png" alt=""> -->
		</div>
		<!-- 图 -->
		<div class="imgList" >
			<div v-for="item in list.medicinal_bottom" class="listOne" @click="detail(item.id)">
				<img :src="item.img" alt="">
				<div class="write">
					<div class="mask"></div>
					<p>{{item.name}}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// 引入axios
	import axios from 'axios';
	// 引入端口配置文件
	import API_LIST from '@/list.config.js';
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import qs from 'qs'
	export default {

		name: "drugInfo",
		props: {

		},
		data() {
			return {
				hei:'367px',
				list:[],
				list2:[{
					name:'头',
					list:['脑瘫','脑出血','脑梗塞','脑肿瘤','脑梗塞','脑动静脉畸形','脑溢血','垂体瘤','脑震荡','癫痫','脑寄生虫病','病毒性脑膜炎','化脓性脑膜炎','脑包虫病','颅内占位性病变','脑积水']
				},{
					name:'鼻',
					list:['鼻炎','鼻窦炎','鼻出血','鼻疖','鼻前庭炎']
				},{
					name:'眼',
					list:['急性泪囊炎','慢性泪囊炎','泪道阻塞','泪腺肿瘤等','霰粒肿']
				},{
					name:'耳',
					list:['耳鸣','耳聋','中耳炎','耵聍栓塞','老年性耳聋']
				}],
				choose:0,
				n:0,
			}

		},
		watch: {

		},
		computed: {

		},

		created() {
			const that = this;
			that.nav_id = this.$route.query.id

			axios.post(API_LIST.Druginfo_list, {
					
				}, {
					headers: {
						// 'Content-Type': 'application/x-www-form-urlencoded'
						'Content-Type': 'application/json'
					} //加上这个x
				})
				.then(_d => {
					console.log(_d.data)
					if (_d.data.status == 200) {
						that.list = _d.data.list
					} else if (_d.data.status == 409) {

					}
				})
				.catch(function(error) {
					console.log(error);
				});

		},
		mounted() {

		},

		methods: {
			sort(_name){
				this.$router.push({path:'/SortList',query:{
					id:4,
					data_name:_name
				}})
			},
			// 
			detail(_id){
				this.$router.push({
					path:'/ProductDetail',
					query:{
						id:_id
					}
				})
			},
			// 
			chooses(_id){
				console.log(_id)
				this.choose = this.n = _id;
			},
			// 资讯详情
			detail2(_id) {
				this.$router.push({
					path: '/Detail',
					query: {
						id: _id
					}
				})
			},
		}
	};
</script>

<style scoped="scoped" lang="scss">
	.drugInfo {
		width: 850px;
		.top{
			width: 100%;
			display: flex;
			flex-direction: row;
			.sort{
				height: 420px;
				width: 286px;
				// background-color: #183239;
				span{
					display: inline-block;
					width: 100%;
					background-color: #F0F0F0;
					color: #000;
					height: 32px;
					line-height: 32px;
					box-sizing: border-box;
					padding: 0 0 0 20px;
					position: relative;
					label{
						position: absolute;
						right: 10px;
						top: 0;
						bottom: 0;
						margin: auto;
						color: #999999;
					}
				}
				span:after{
					content: '';
					height: 20px;
					width: 4px;
					background-color: #0078F0;
					position: absolute;
					left: 10px;
					top: 0;
					bottom: 0;
					margin: auto;
				}
				ul{
					// height: 335px;
					display: flex;
					flex-wrap: wrap;
					flex-direction: row;
					justify-content: space-around;
					// align-items: flex-start;
					// align-content:flex-start
					// justify-content: space-around;
					li{
						width: 110px;
						height: 32px;
						text-align: center;
						line-height: 32px;
						color: #000;
						cursor: pointer;
					}
					li:hover{
						color: #0078F0;
					}
				}
			}
			.lunbo{
				width: 560px;
				height: 420px;
				cursor: pointer;
				margin: 0 0 0 30px;
			}
		}
		.te{
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			margin: 15px 0;
			.title{
				font-size: 20px;
				font-weight: 700;
				background: #f0f0f0;
				padding: 10px 0px 10px 30px;
				box-sizing: border-box;
				position: relative;
			}
			.title::after{
				content: '';
				position: absolute;
				width: 3px;
				height: 18px;
				left: 20px;
				top: 0;
				bottom: 0;
				margin: auto;
				background-color: #0078F0;
			}
			.productList{
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				margin: 15px 0;
			}
			.productOne{
				display: flex;
				flex-direction: column;
				box-sizing: border-box;
				padding: 10px;
				background-color: #fff;
				img{
					width: 192px;
					height: 144px;
				}
				.info{
					display: flex;
					flex-direction: column;
					width: 120px;
					margin: 0 0 0 10px;
					h4{
						line-height: 35px;
						height: 35px;
						overflow: hidden;
					}
					p{
						font-size: 10px;
						color: #999;
						margin: 10px 0 0 0;
						height: 30px;
						overflow: hidden;
					}
					div{
						margin: 25px 0 0 0;
						display: flex;
						flex-direction: row;
						align-items: center;
						.written{
							font-size: 10px;
						}
						.icon{
							font-size: 10px;
							color: #f00;
						}
						.money{
							font-size: 20px;
							color: #f00;
						}
					}
					
				}
			}
		}
		.organ{
			display: flex;
			flex-direction: column;
			height: 155px;
			.organTop{
				display: flex;
				flex-direction: row;
				li{
					width: 123px;
					height: 34px;
					background: #eee;
					text-align: center;
					line-height: 34px;
					border: 1px solid #D2D2D2;
					color: #333;
					cursor: pointer;
				}
				.choose{
					color: #fff !important;
					background-color: #63C22F !important;
				}
			}
			.disease{
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				margin: 10px 0 0 0;
				width: 660px;
				li{
					width: 100px;
					height: 26px;
					margin: 0px 10px 10px 0;
					line-height: 26px;
					position: relative;
					cursor: pointer;
				}
				li::after{
					position: absolute;
					content: '';
					width: 26px;
					height: 7px;
					background: #eee;
					left: 30px;
					bottom: -7px;
				}
				li::before{
					position: absolute;
					content: '';
					width: 26px;
					height: 7px;
					background: #eee;
					left: 60px;
					bottom: -7px;
				}
				.ic{
					position: absolute;
					width: 26px;
					height: 7px;
					background: #F6CE93;
				}
				.ic2{
					position: absolute;
					width: 26px;
					height: 7px;
					background: #BDDCAB;
				}
			}
		}
		.new{
			display: flex;
			ul{
				display: flex;
				flex-direction: column;
				li{
					display: flex;
					flex-direction: row;
					margin: 10px 0 0 0;
					cursor: pointer;
					span{
						color: #63C22F;
						margin-right: 6px;
						font-size: 15px;
					}
					p{
						font-size: 15px;
					}
				}
				
			}
		}
		.imgNew{
			display: flex;
			margin: 15px 0 0 0;
			ul{
				display: flex;
				flex-direction: column;
				width: 100%;
				li{
					display: flex;
					flex-direction: row;
					// width: 100%;
					
					height: 125px;
					padding: 14px;
					margin: 0 0 10px 0;
					box-sizing: border-box;
					cursor: pointer;
					img{
						width: 200px;
						height: 96px;
					}
					.info{
						margin: 0 0 0 10px;
						width: 650px;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						h4{
							width: 100%;
							font-size: 20px;
							overflow: hidden;
						}
						div{
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							span{
								font-size: 14px;
								color: #999999;
							}
						}
					}
				}
			}
			img{
				width: 215px;
				height: 396px;
				margin: 0 0 0 10px;
			}
		}
		.imgList{
			width: 100%;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			border: 1px solid #E6E6E6;
			box-sizing: border-box;
			padding: 10px;
			// justify-content: space-between;
			
			.listOne{
				display: flex;
				flex-direction: column;
				position: relative;
				margin: 0 16px 10px 0;
				img{
					width: 190px;
					height: 127px;
				}
				.write{
					// position: absolute;
					// bottom: 0;
					// left: 0;
					// width: 211px;
					// .mask{
					// 	width: 100%;
					// 	height: 20px;
					// 	position: absolute;
					// 	bottom: 0;
					// 	left: 0;
					// 	background-color: #fff;
					// 	opacity: 0.5;
					// }
					p{
						margin: 5px 0;
						color: #000;
					}
				}
			}
		}
	}
	
	.el-carousel__item h3 {
		color: #475669;
		font-size: 14px;
		opacity: 0.75;
		line-height: 150px;
		margin: 0;
		text-align: center;
	}
	
	.el-carousel__item:nth-child(2n) {
		background-color: #99a9bf;
	}
	
	.el-carousel__item:nth-child(2n + 1) {
		background-color: #d3dce6;
	}
</style>
